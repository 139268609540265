$contentWidth: 350px;

lib-collapse-panel-old {
  max-width: var(--content-panel-width, $contentWidth);

  &:not(.visible) {
    width: 0;
  }

  aside {
    box-shadow: 55px 0px 35px -75px var(--bgContrast);
    position: sticky;
    background: linear-gradient(
      0deg,
      var(--background) 0%,
      var(--bgGrey) 10%,
      var(--bgGrey) 90%,
      var(--background) 100%
    );
  }

  .content-toggle {
    background: var(--grey);
    color: var(--buttonText);
    border-radius: 0 50% 50% 0;
    clip-path: inset(0 0 0 20px);
    right: -1.7rem;
    top: 0.6rem;
    text-align: right;

    &:hover {
      clip-path: inset(0 0 0 10px);
      transform: translate(10px, 0);
    }

    @media screen and (max-width: 680px) {
      top: 50vh;
      position: fixed !important;
    }
  }

  &:not(.visible) .content-toggle {
    left: -1.2rem;
    right: auto;
  }

  &.visible {
    .dragHandle {
      background: linear-gradient(0deg, var(--background) 5%, var(--grey) 20%, var(--grey) 80%, var(--background) 95%);
      width: 2px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        right: -5px;
      }

      &.dragging {
        background: red;
      }
    }

    aside {
      width: var(--content-panel-width, $contentWidth);
    }

    .content-toggle {
      @media screen and (max-width: 680px) {
        left: calc(var(--content-panel-width, $contentWidth) - 20px);
        right: auto;
      }
      @media screen and (max-width: 490px) {
        right: 0px;
        left: auto;
        transform: rotate(180deg) translate(-20px, 0);
        &:hover {
          transform: rotate(180deg) translate(-10px, 0);
        }
      }
      @media screen and (min-width: 490px) {
        .mat-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}
