@import './functions';
@import './variables';

// Overrides to the material styles

.mat-checkbox-layout .mat-checkbox-label {
  white-space: pre-wrap;
}

// .mat-mdc-tab-group.vertical {
//   transform: rotate(-90deg);
//   .mat-tab-label {
//     transform: rotate(90deg);
//   }
// }

.mat-mdc-tab-group {
  .mat-mdc-tab-labels,
  .mat-tab-link {
    color: inherit;
  }
  .mat-mdc-tab-label-container {
    border: 0;
  }
  &.vertical {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    .mat-tab-header {
      border-bottom: 0;
      // margin-right: .5rem;
      overflow: visible;
    }
    .mat-mdc-tab-list {
      position: relative;
      background: linear-gradient(
        0deg,
        var(--panelBack) 0%,
        var(--bgGrey) 10%,
        var(--bgGrey) 90%,
        var(--panelBack) 100%
      );
      box-shadow: 14px 0px 13px -18px var(--bgContrast);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: calc(100% - 1px);
        background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(5%, var(--panelBack)),
          color-stop(20%, var(--bgGrey)),
          color-stop(80%, var(--bgGrey)),
          color-stop(95%, var(--panelBack))
        );
        background: linear-gradient(
          0deg,
          var(--panelBack) 5%,
          var(--bgGrey) 20%,
          var(--bgGrey) 80%,
          var(--panelBack) 95%
        );
      }
    }
    .mat-mdc-tab-label-container {
      overflow: visible;
      .mat-mdc-tab {
        padding: 0;
        min-width: 48px;
        .app-icon,
        .mat-icon {
          width: 24px;
          height: 24px;
        }
        .mdc-tab-indicator__content--underline {
          border-width: 0 var(--mdc-tab-indicator-active-indicator-height) 0 0;
          border-style: none solid none none;
          height: 100%;
        }
      }
    }
    .mat-mdc-tab-labels {
      flex-direction: column;
    }
    .mat-ink-bar {
      display: none;
    }
    .mat-mdc-tab-body-wrapper {
      flex: 1;
    }
    &.icon-tabs .mat-tab-label {
      padding: 0;
      min-width: 0;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      line-height: 40px;
    }
    .mat-mdc-tab-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.mat-divider {
  border-top-color: var(--borderColor);
}
.mat-calendar-table-header-divider::after {
  background: var(--borderColor);
}
.mat-input-element {
  line-height: 1.3rem;
}

.mat-mdc-tab-group.vertical,
.mdc-dialog__container {
  .mat-checkbox-label {
    margin-left: 8px;
  }
  .mat-mdc-list-base {
    padding-top: 0;
  }
  .mat-menu-item,
  .mat-list-option {
    font-size: 16px;
    padding: 0 16px;
    .mat-mdc-list-item-content {
      padding: 0;
    }
    @media screen and (min-width: 960px) {
      line-height: 28px;
      height: 28px;
    }
  }
}
.mat-menu-panel {
  background: var(--panelBack);
  .mat-menu-item {
    color: var(--textColor);
    .mat-icon {
      color: currentColor;
    }
  }
}
.mat-mdc-list-base {
  .mat-mdc-list-item,
  .mat-menu-item,
  .mat-list-option {
    color: inherit;
  }
  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    height: 38px;
  }
  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin: 0;
  }
}

.mat-accent {
  .mat-checkbox-checked .mat-checkbox-background,
  .mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: var(--primary);
  }
  .mat-checkbox-frame {
    border: 1px solid var(--borderColor);
    z-index: 2;
  }
  .mat-checkbox-checkmark-path {
    stroke: getTextColor($primary) !important;
  }
}
.mat-pseudo-checkbox {
  color: var(--borderColor);
}
.mat-mdc-checkbox {
  width: 100%;
  .mdc-form-field {
    width: 100%;
  }
  .mdc-checkbox {
    width: 40px;
    // .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    // .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    // .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
    //   border-color: var(--borderColor);
    // }
  }
  .mdc-label {
    padding-left: 0;
    width: 100%;
  }
}
.mat-pseudo-checkbox {
  &.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
  &.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    border-color: var(--borderColor);
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: var(--accent);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--accent);
}
.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--panelBack);
  --mdc-dialog-subhead-color: var(--textColor);
}
.settings-item-dialog {
  &.fullscreen {
    [app-widget-settings] {
      display: grid;
      grid-template-rows: 3rem minmax(10.5rem, calc(60vh - 9rem));
      position: relative;
    }
    .mat-mdc-dialog-actions {
      position: absolute;
      top: 0;
      right: 0;
    }
    .mdc-dialog__container {
      min-width: 480px;
      min-height: 312px;
      max-height: 60vh;
      .mat-mdc-tab-group.vertical {
        margin: 0;
        width: 100%;
        max-width: none;
      }
      .mat-tab-body-content {
        max-height: 60vh;
        flex: 1;
      }
    }
  }
}

.draggable-dialog .mdc-dialog__container .mat-dialog-title {
  cursor: grab;
  &:active {
    cursor: move;
  }
}

// UPDATE for angular 15 with MDC components
.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.27);
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: var(--panelBack);
  background: var(--panelBack);
  color: var(--textColor);
  .mat-subheader,
  .mat-mdc-card-subtitle {
    color: var(--mdc-icon-button-disabled-icon-color);
  }
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-color: var(--borderColor);
  .mat-button-toggle + .mat-button-toggle {
    border-color: var(--borderColor);
  }
  .mat-icon {
    color: inherit;
  }
}
.mat-button-toggle-appearance-standard {
  background: transparent;
  color: var(--textColor);
  &.mat-button-toggle-disabled {
    background: transparent;
    color: var(--muteText);
  }
  &.mat-button-toggle-checked {
    background: hsla(var(--primary-h), var(--grey-s), var(--textColor-l), 0.22);
    color: var(--textColor);
  }
}

.mat-mdc-form-field {
  &.mat-mdc-form-field-appearance-legacy,
  &.mat-mdc-form-field-appearance-fill {
    .mdc-line-ripple,
    .mdc-line-ripple::before {
      background-color: var(--borderColor);
    }
    &.mat-mdc-form-field-disabled .mdc-line-ripple {
      background-color: transparent;
      background-image: linear-gradient(to right, var(--borderColor) 0%, var(--borderColor) 33%, transparent 0%);
      background-size: 4px 100%;
      background-repeat: repeat-x;
    }
  }
  .mat-mdc-text-field-wrapper {
    flex: unset;
  }
}

body {
  .mdc-text-field {
    &.mdc-text-field--disabled {
      color: var(--disabledText);
      background-color: var(--disabledBack);
      .mdc-text-field__input {
        color: var(--muteText);
      }
      .mdc-floating-label {
        color: var(--muteText);
      }
    }
    &:not(.mdc-text-field--disabled) {
      background-color: initial;
      .mdc-text-field__input {
        color: inherit;
      }
      .mdc-floating-label {
        color: var(--muteText);
      }
      &:hover .mdc-line-ripple::before {
        border-color: var(--textColor);
      }
      .mdc-line-ripple::before {
        border-color: var(--muteText);
      }
    }
  }
}
.mat-mdc-form-field {
  .mat-mdc-form-field-ripple {
    background-color: var(--accentText);
  }
  .mat-input-element {
    caret-color: var(--accentText);
    &:disabled {
      color: var(--muteText);
    }
  }
  &.mat-focused {
    .mat-mdc-form-field-label {
      color: var(--textColor);
    }
    .mat-mdc-form-field-ripple {
      background-color: var(--accentText);
    }
    &.mat-primary .mat-select-arrow {
      color: var(--textColor);
    }
  }
  .mat-select.mat-select-disabled .mat-select-arrow {
    color: var(--muteText);
  }
}
.mat-input-element {
  caret-color: var(--accentText);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--textColor);
}
.mat-datepicker-content {
  background-color: var(--panelBack);
  .mat-calendar-arrow {
    fill: var(--textColor);
  }
  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: var(--buttonDisabled);
  }
}
.mat-calendar-body-cell-content {
  color: var(--textColor);
}
.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--muteText);
}
.mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--grey);
  color: var(--buttonText);
}
.mat-datepicker-toggle .mat-mdc-icon-button:disabled {
  color: var(--muteText);
}
.mat-select-arrow-wrapper .mat-select-arrow {
  color: var(--muteText);
}
.mat-mdc-select-panel {
  min-width: 5rem;
}
// .mat-select-value,
// .mat-mdc-select-value {
//   color: var(--textColor);
// }
.mat-select-disabled .mat-select-value {
  color: var(--muteText);
}
.mat-select-panel {
  background: var(--panelBack);
}
.mat-option {
  color: var(--option);
  &.mat-active {
    color: var(--textColor);
  }
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--textColor);
}

.mat-progress-bar-buffer {
  background-color: var(--panelBack);
}
.mat-progress-bar-fill::after {
  background-color: var(--accent);
}

.mat-toolbar {
  background: var(--buttonFace);
  color: var(--buttonText);
}

.mat-tree-node,
.mat-nested-tree-node {
  color: var(--textColor);
}

.mat-expansion-panel,
.mdc-dialog__container {
  background: var(--panelBack);
  color: var(--textColor);
  .mat-expansion-panel-header-title {
    color: inherit;
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: inherit;
  }
}
.mat-autocomplete-panel {
  background: var(--panelBack);
}
.mat-mdc-form-field-appearance-legacy .mat-hint {
  color: var(--muteText);
}

.mat-mdc-tab,
.mat-mdc-tab-link {
  .mdc-tab__text-label,
  .mdc-tab__text-label {
    color: var(--muteText);
  }
  &:not(.mat-mdc-tab-disabled) {
    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color: var(--muteText);
    }
    &.mdc-tab--active .mdc-tab__text-label {
      color: var(--textColor);
    }
  }
}
.mat-mdc-form-field {
  .mat-mdc-floating-label.mdc-floating-label {
    font-size: 1.1rem;
    line-height: 0.9rem;
  }
  .mdc-text-field,
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
  .mat-mdc-form-field-subscript-wrapper:not(:has(.mat-mdc-form-field-hint)):not(:has(.mat-mdc-form-field-error)) {
    height: 0.4rem;
  }
  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    font-size: 0.8rem;
  }
  .mat-mdc-form-field-error {
    color: var(--warning);
  }
}

.mat-calendar-controls {
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: 8px;
  }
}
.mat-calendar-content mat-multi-year-view {
  .start-range,
  .end-range {
    background: transparent !important;
  }
}
.mat-datepicker-toggle {
  --mdc-icon-button-state-layer-size: 43px;
}
