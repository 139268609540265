:root {
  --gridster-gap: 5px;

  &:has(.with-rail) {
    --gridster-gap: 12px;
  }
}

gridster {
  @media print {
    overflow: unset !important;
    height: auto;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(300px, 350px);
    grid-gap: var(--gridster-gap);
  }

  &.mobile {
    overflow-y: unset !important;
    overflow-x: unset !important;
  }

  gridster-item {
    vertical-align: top;
    background: transparent;
    border-radius: var(--borderRadius);
    overflow: unset !important;
    // overflow: hidden;
    @media print {
      display: inline-block !important;
      width: auto !important;
      height: auto !important;
      position: static;
      transform: none !important;
      max-width: 100%;
      margin: 0 !important;
    }
    @media screen and (max-width: 480px) {
      min-height: 250px;
    }
  }

  .gridster-column,
  .gridster-row {
    border-color: var(--bgGrey) !important;
    background: var(--bgGrey);
    opacity: 0.3;
  }

  .gridster-item-moving {
    z-index: 100000 !important;
  }

  .gridster-preview {
    background: color-mix(in srgb, var(--background), var(--panelBack) 70%);
    border-radius: var(--borderRadius);
    overflow: hidden;
  }
}
