.mat-mdc-list-base {
  padding-top: 0 !important;
}

.mat-mdc-list-base .mat-mdc-list-item,
.cdk-tree .cdk-tree-node .container {
  &.inactive {
    --mdc-list-list-item-label-text-color: var(--muteText);
    position: relative;
    // border: 1px solid var(--warning);
    background: var(--disabledBack);
    color: var(--muteText);
    &.selected app-asset-tree-icon {
      background-color: var(--lightGrey);
      background: linear-gradient(140deg, var(--lightGrey), var(--grey)) !important;
      border-color: var(--borderColor);
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: var(--borderColor);
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4px;
      background-color: var(--borderColor);
      opacity: 1;
    }
  }
}
