body {
  .mat-mdc-button-base,
  .mat-button-base {
    &.mdc-button--raised[disabled] {
      --mdc-protected-button-disabled-label-text-color: var(--buttonMute);
    }
    &[disabled][disabled] {
      --mdc-icon-button-disabled-icon-color: var(--muteText);
    }
    &[disabled='true'] {
      &.mdc-button.mat-primary,
      &.mdc-button.mat-accent,
      &.mdc-button.mat-warn,
      &.mdc-button--unelevated.mat-primary,
      &.mdc-button--unelevated.mat-accent,
      &.mdc-button--unelevated.mat-warn,
      &.mat-mdc-raised-button.mat-primary,
      &.mat-mdc-raised-button.mat-accent,
      &.mat-mdc-raised-button.mat-warn,
      &.mdc-fab.mat-primary,
      &.mdc-fab.mat-accent,
      &.mdc-fab.mat-warn {
        background-color: hsla(var(--primary-h), var(--grey-s), var(--textColor-l), 0.22);
        color: var(--buttonDisabled);
      }
      &.mdc-icon-button.mat-primary,
      &.mdc-icon-button.mat-accent,
      &.mdc-icon-button.mat-warn,
      &.mat-mdc-outlined-button.mat-primary,
      &.mat-mdc-outlined-button.mat-accent,
      &.mat-mdc-outlined-button.mat-warn {
        background-color: transparent;
        color: var(--buttonDisabled);
      }
    }
    .mdc-button__label,
    .mdc-button__label > label {
      display: flex;
      place-items: center;
      place-content: center;
    }
    .mat-icon {
      margin: 0;
      width: auto;
      height: auto;
      font-size: 22px;
    }
  }
}
a.mdc-icon-button:hover {
  text-decoration: none;
}
