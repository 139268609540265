.nomap-container {
  display: flex;
  border: 1px solid var(--grey);
  margin-bottom: 1.2rem;
  min-height: 500px;
  width: 100%;
  align-content: center;
  justify-content: center;
}

google-map {
  iframe {
    display: none;
  }
  .gm-style-mtc button,
  .gm-control-active {
    background: var(--buttonFace) !important;
    color: var(--buttonText) !important;
  }
  .gmnoprint button + div {
    background-color: var(--borderColor) !important;
    color: var(--buttonText) !important;
  }
  .gm-svpc {
    background-color: var(--buttonFace) !important;
    color: var(--buttonText) !important;
  }
}
