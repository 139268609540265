@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/variables';
@import './styles/theme';
@import './styles/material';
@import './styles/elements';
@import './styles/buttons';
@import './styles/chart';
@import './styles/gridster';
@import './styles/meters';
@import './styles/table';
@import './styles/widget';
@import './styles/map';
@import './styles/box';
@import './styles/collapse_panel';

@import './styles/print';
@import './styles/iframe';

@import './styles/chameleon-utils';

@import './styles/ag-grid';
