ag-grid-angular {
  --ag-value-change-value-highlight-background-color: light-dark(#fffea9, #403f1f);

  display: block;
  height: 100%;
  width: 100%;

  // region Cell styles.
  // These are styles that are applied to applicable cells.
  // See grid.utils.ts for the classes that are applied to cells.
  .temperature-negative {
    color: #3498db;
  }

  .temperature-positive {
  }

  .et-deviation-over-threshold {
    color: #e74c3c;
  }

  .et-deviation-under-threshold {
    color: #2ecc71;
  }

  .et-deviation-within-threshold {
  }

  // endregion
}
