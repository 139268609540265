.print-only {
  @media only screen {
    display: none !important;
  }
}

.screen-only {
  @media only print {
    display: none !important;
  }
}
