@import './variables';

@keyframes fade-out {
  to {
    opacity: 0;
  }
}
::view-transition-group(*) {
  animation-duration: 0.2s;
}

@page {
  size: A4;
  margin: 0;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  background: var(--background);
  overflow: hidden;
  @media print {
    max-width: 210mm;
  }
}
body {
  margin: 0;
  font-family: var(--baseFont);
  font-size: var(--baseFontSize);
  color: var(--textColor);
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: var(--accentText);
  font-size: 1.1rem;
  text-underline-offset: 4px;
  &:hover {
    text-decoration: underline;
  }
  @media print {
    color: var(--primary);
  }
}
.mat-typography {
  h1 {
    letter-spacing: normal;
    margin: 0 0 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
  h2 {
    letter-spacing: 0.0125em;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.125rem;
    margin: 0 0 16px;
  }
  h5 {
    font-weight: 600;
    font-size: 0.83rem;
    line-height: 1.125rem;
  }
}
video {
  width: 100%;
}

*:not(svg.highcharts-root) {
  h4,
  b,
  strong {
    font-weight: 600;
    font-size: 1.1rem;
    opacity: 0.9;
    margin-top: 0.9rem;
    margin-bottom: 0.6rem;
  }
}

.nav-head {
  min-height: 3.6rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-snack {
  white-space: pre-wrap;
}
