:root {
  --widget-border: var(--borderColor);
  --widget-header-background: linear-gradient(0deg, var(--panelBack), var(--bgGrey));
  &:has(.with-rail) {
    --widget-border: transparent;
    --widget-header-background: transparent;
  }
  .widget.fullscreen {
    padding: 0;
  }
}
.widget {
  display: block;
  height: 100%;
  &:not(.fullscreen) {
    transition: box-shadow 0.2s ease-in-out;
    &.hover {
      box-shadow: 0 0 10px -4px var(--bgContrast);
    }
    @media print {
      height: 100%;
      page-break-inside: avoid;
    }
    .disclaimer {
      position: absolute;
      bottom: 0rem;
      left: 0;
      text-align: right;
      z-index: 2;
      .mat-icon {
        font-size: 2rem;
      }
    }
  }
  &.fullscreen {
    // max-height: calc(100vh - 8rem);
    padding: 0.5rem 1rem;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        margin: 0;
        padding: 8px 0;
      }
    }
    &:not(:has(.chart-container)) .chart,
    .chart-container {
      width: 100%;
      min-height: 300px; // Necessary in order to make space for the container before rendering is done.
      + section {
        tr:last-of-type td {
          border-bottom: 0;
        }
      }
    }
  }
  main {
    &:not(:has(.chart-container)) .chart,
    .chart-container {
      height: 100%;
      overflow: hidden;
      view-transition-name: var(--chart-id, chart);
    }
    .responsive-table {
      view-transition-name: var(--grid-id, table);
    }
  }

  // Announcements
  &.fullscreen {
    main > .app-warn.disclaimer {
      position: unset;
    }
  }
  &:not(.fullscreen) {
    main > .app-warn.disclaimer {
      flex: 0;
      position: absolute;
      z-index: 2;
      left: 0;
      > div {
        align-items: flex-end;
        margin: 0 0 0 auto;
        font-size: 0.8rem;
        flex-direction: row;
      }
      .mat-icon {
        width: 2rem;
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
  .app-warn {
    &.disclaimer {
      padding: 10px;
      flex: 0;
      font-size: 0.8rem;
      > div {
        text-align: center;
        max-height: fit-content;
        cursor: help;
        width: 100%;
        &:hover {
          background: var(--bgGrey);
        }
        > span {
          display: block !important;
          flex: 1;
          text-align: left;
          align-items: baseline;
        }
      }
    }
  }
  [app-widget-settings] {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  header[app-widget-header] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  video {
    max-height: 70vh;
    max-width: 70vw;
  }
  .menu-content {
    padding: 0;
    max-height: 16rem;
    overflow: auto;
  }

  .chart {
    @media only screen {
      height: 100%;
      width: 100%;
    }
  }

  .mat-table {
    width: 100%;
    background: var(--panelBack);
  }

  section[class*='mat-elevation'] {
    .ag-root-wrapper {
      border: 0;
    }
    @media print {
      box-shadow: none;
    }
    .mat-toolbar {
      height: 44px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }
}
