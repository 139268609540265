$iconSize: 2.2em;
.tile {
  overflow: hidden;
  border-radius: 0.5em;
  padding: 1em;
  place-content: center;
  place-self: normal;
  // place-items: baseline;
  gap: 0;
  position: relative;
  height: 100%;
  width: 100%;
  // Place tile dynamically in the grid based on given css variables
  grid-column: var(--cols) / calc(var(--cols) + var(--width));
  grid-row: var(--rows) / calc(var(--rows) + var(--height));
  // Text colors should be calculated based on contrast to background
  color: var(--textColor);
  // color: color-contrast(#000000 vs #ffffff);
  // Default tile grid
  display: flex;
  flex-direction: column;

  // Tile opacity

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.5;
    background: var(--tileBackground, var(--brandingColor));
  }
  // All headers
  header {
    display: flex;
    align-items: center;
    line-height: 1em;
  }
  span {
    display: block;
    flex: 1;
  }
  pre {
    font-family: inherit;
    line-height: normal;
    font-size: 1.1em;
  }
  // Tile main header
  > header {
    grid-area: header;
    height: #{$iconSize};
    font-weight: bold;
    font-size: 1.3em;
    width: 100%;
    .mat-icon {
      width: #{$iconSize};
      height: #{$iconSize};
      padding: 0.1em;
      svg {
        stroke-width: 2;
      }
    }
  }
  .mat-icon svg {
    stroke: currentColor;
  }
  > main {
    flex: 1;
    display: grid;
    grid-template:
      'historic line  now  ' auto
      'graph    graph graph' minmax(0, 0.9fr)
      / 1fr 2px 1fr;

    .graph {
      grid-area: graph;
    }

    // Divider - can be used both horizontally and vertically
    .line {
      grid-area: line;
      background-color: var(--textColor);
      opacity: 0.3;
      border-radius: 2px;
      width: 100%;
      height: 100%;
      display: block;
    }

    // tile sections
    > article {
      width: 100%;
      min-width: 10em;
      height: 100%;
      display: grid;
      gap: 0.5em;
      grid-template:
        'sHeader' 2.5em
        'sContent' 1fr / 1fr;
      place-items: center;
      // Section items - header
      > header {
        opacity: 0.8;
        grid-area: sHeader;
        .mat-icon {
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
        }
      }
      // Section items - content
      > section {
        grid-area: sContent;
        align-self: flex-start;
        table {
          width: 100%;
          font-size: 1.2em;
          line-height: 1em;
          th {
            text-align: right;
            padding-right: 0.5em;
          }
          td {
            padding-left: 0.5em;
            text-align: left;
          }
        }
        .sum {
          font-size: 2em;
        }
      }
    }
    // Section types
    .historic {
      grid-area: historic;
      .sum {
        display: none;
      }
    }
    .now {
      grid-area: now;
    }
  }

  // --------------------------------------------
  // Tile variants depending on height
  // --------------------------------------------
  &.tallAbs.miniWAbs > main {
    grid-template:
      'now     ' auto
      'line    ' 2px
      'historic' auto
      'graph   ' minmax(0, 0.9fr)
      / 1fr;
    .historic,
    .line,
    .graph {
      display: grid;
    }
    header {
      width: auto;
    }
  }
  &.shortAbs.miniWAbs > main {
    grid-template:
      'now     ' auto
      'line    ' 2px
      'historic' auto
      / 1fr;
    gap: 10px;
    .historic {
      display: grid;
    }
  }
  &.shortAbs {
    > main {
      grid-template-rows: 1fr;
    }
    .graph,
    .historic {
      display: none;
    }
  }
  &.miniHAbs {
    > main {
      grid-template-columns: 1fr;
      .sum {
        display: block;
      }
      .graph,
      .historic,
      table {
        display: none;
      }
    }
  }
  &.microHAbs {
    > main {
      place-self: center;
      gap: 1em;
      article {
        gap: 0;
        display: flex;
        place-content: center;
        min-width: 0;
        header {
          display: inline-block;
          span {
            display: none;
          }
        }
        section {
          flex: 1;
          align-self: center;
        }
      }
    }
  }
  // --------------------------------------------
  // Tile variants depending on width
  // --------------------------------------------
  &.wideAbs.shortAbs {
    > main {
      grid-template:
        'historic  line now' minmax(0, 0.9fr)
        'graph graph graph' minmax(0, 0.9fr)
        / auto 2px auto;
    }

    .historic .sum {
      display: none;
    }
    .graph,
    .historic,
    .line,
    table {
      display: grid;
    }
  }
  &.wideAbs.shorterAbs {
    > main {
      grid-template:
        'historic  line now' minmax(0, 0.9fr)
        / auto 2px auto;
    }

    .graph,
    .historic .sum {
      display: none;
    }
    .historic,
    .line,
    table {
      display: grid;
    }
  }
  &.wideAbs.miniHAbs {
    > main {
      grid-template:
        'historic  line now' minmax(0, 0.9fr)
        / auto 2px auto;
    }
    .graph {
      display: none;
    }
    .historic,
    .line {
      display: grid;
    }
  }
  &.wideAbs.microHAbs {
    > main {
      grid-template: 'historic line now' minmax(0, 0.9fr) / minmax(0, 1fr) 2px minmax(0, 1fr);
    }
    .historic,
    .now {
      display: flex;
      > section {
        flex: 1;
      }
    }
  }
  &.extraWideAbs.shortAbs,
  &.extraWideAbs.miniHAbs {
    > main {
      grid-template:
        'historic  line now graph' minmax(0, 0.9fr)
        / auto 2px auto minmax(0, 0.9fr);
    }
    .now,
    .historic,
    .graph {
      display: grid;
    }
  }
  &.miniWAbs.microHAbs {
    > main {
      grid-template: 'now' minmax(0, 1fr) / minmax(0, 1fr);
      place-items: center;
    }
    .now {
      grid-area: 1 / 1 / -1 / -1;
    }
  }
  &.miniWAbs {
    > main {
      grid-template-columns: 1fr;
    }
    article {
      grid-column: 1 / -1;
    }
    .historic {
      display: none;
    }
  }
  &.extraWideAbs.microWAbs,
  &.wideAbs.microWAbs {
    > main {
      grid-template:
        'historic  line now graph' minmax(0, 0.9fr)
        / auto 2px auto minmax(0, 0.9fr);
    }
    .graph {
      display: grid;
    }
    header span {
      display: block;
    }
  }
  &.microWAbs {
    header span {
      display: none;
    }
  }

  // -----------------------------------------------
  // Override highcharts styling to enable themes
  // -----------------------------------------------
  .highcharts-container {
    font-size: 0.9em;
    max-height: calc(var(--height) * 2em);
    .highcharts-root {
      display: grid;
      font-family: inherit !important;
    }
    .highcharts-background,
    .highcharts-plot-background,
    .highcharts-plot-border,
    .highcharts-graph,
    .highcharts-tracker-line,
    .highcharts-axis-line,
    .highcharts-halo {
      fill: none;
    }
    .highcharts-axis-labels,
    .highcharts-point {
      fill: var(--textColor);
    }
    .highcharts-axis-line,
    .highcharts-tick,
    .highcharts-yaxis-grid .highcharts-grid-line,
    .highcharts-color-0,
    .highcharts-point {
      stroke: var(--textColor);
    }
    .highcharts-axis-labels {
      font-size: 0.9em;
    }
    .highcharts-tick {
      stroke-opacity: 0.5;
    }
    .highcharts-yaxis-grid .highcharts-grid-line {
      stroke-opacity: 0.3;
    }
    // .highcharts-color-0 {
    //   // fill: var(--textColor);
    // }
    .highcharts-point {
      outline: none;
      stroke-width: 1px;
    }
    .highcharts-tooltip {
      cursor: default;
      pointer-events: none;
      white-space: nowrap;
      transition: stroke 150ms;

      .highcharts-tooltip-box {
        stroke-width: 1px;
        fill: var(--mapBack);
        fill-opacity: 0.85;
      }
      text {
        fill: var(--primary);
        stroke-width: 0;
        &.highcharts-header {
          font-size: 0.85em;
        }
        &.highcharts-strong {
          font-weight: bold;
        }
      }
    }
  }
}

.highcharts-strong {
  font-weight: bolder;
  font-size: 1.3em;
}
