.app-box {
  flex: 1;
  color: var(--textColor);
  display: flex;
  justify-content: center;
  padding: 1px 1rem;
  &.shown {
    width: 100%;
  }
  &.warning {
    color: var(--muteText);
    > div > span {
      color: var(--textColor);
    }
  }
  &.bordered {
    border: 1px solid var(--borderColor);
    border-radius: 4px;
  }
  > div {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    > span {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  &.stacked {
    .mat-icon {
      line-height: 2.6rem;
    }
    > div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      > span {
        align-items: start;
        flex: 1;
      }
    }
  }
  &.filled {
    background: var(--bgGrey);
  }
  button {
    display: block;
  }
  .mat-icon {
    font-size: 3em;
    line-height: 4rem;
    height: auto;
    width: auto;
    opacity: 0.8;
  }
  .details {
    font-size: smaller;
    color: var(--muteText);
  }
}
